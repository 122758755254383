.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@media (min-width: 769px) {
  .information {
    margin-left: -60px;
  }
}

@media (max-width: 768px) {
  .information {
    margin-left: -20px;
    font-size: 18px;
  }
}

@media (max-width: 480px) {
  .information {
    margin-left: -10px;
    font-size: 16px;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  height: 100%;
  width: 100%;
  margin-top: 10%;
}

.login-row {
  width: 100%;
}

.login-card {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.login_submit_btn {
  width: 100%;
  background-color: #007bff;
  border: none;
  color: white;
  cursor: pointer;
  border-radius: 5px;
}

.login_submit_btn:hover {
  background-color: #0056b3;
}

.form_help_text {
  margin-top: 10px;
  text-align: center;
}

.form_help_text a {
  color: #007bff;
  text-decoration: none;
}

.form_help_text a:hover {
  text-decoration: underline;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
